@use '../../common/sass/base.module.scss' as *;

.ContentPageContent {
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	touch-action: pan-y;
}

.header {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;

	@media #{$query-tablet-large} {
		padding-left: 66px;
		padding-right: 66px;
	}

	@media #{$query-mobile} {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.header-content {
	display: block;
	position: relative;
	width: 100%;
	padding-left: 310px;
	padding-right: 80px;
	position: relative;
	transform: matrix(1, 0, 0, 1, 0, 0);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 900px;

	@media #{$query-tablet-large} {
		margin-right: auto;
		padding-top: 100px;
		padding-left: 0;
		padding-right: 0;
		// width: 80%;
		display: block;

		max-width: 780px;
		margin: 0 auto;
	}
	@media #{$query-mobile} {
		width: 100%;
	}
}

.logo {
	position: absolute;
	top: 34px;
	width: 250px;
	height: 111px;

	@media #{$query-tablet-large} {
		max-width: 450px;
		position: static;
		margin-bottom: 50px;
	}
}

.title {
	@include Forza(500);
	color: rgb(255, 255, 255);
	margin: 0 auto;
	margin-left: 0;
	position: relative;
	min-width: 268px;
	padding-bottom: 22px;
	margin-bottom: 22px;
	line-height: 1.1;

	@media #{$query-desktop-large} {
		font-size: 90px;
	}
	@media #{$query-desktop} {
		font-size: 77px;
	}
	@media #{$query-tablet-large} {
		font-size: 60px;
	}
	@media #{$query-mobile} {
		font-size: 32px;
		width: 100%;
	}
}

.description {
	@include Gotham(300);
	line-height: 1.5;
	position: relative;
	z-index: 2;
	color: white;
	min-width: 268px;
	max-width: 380px;

	@media #{$query-desktop-large} {
		font-size: 20px;
	}
	@media #{$query-desktop} {
		font-size: 18px;
	}
	@media #{$query-tablet-large} {
		font-size: 18px;
	}
	@media #{$query-mobile} {
		font-size: 13px;
		line-height: 20px;
		transform: translateY(0);
		min-width: auto;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		top: -25px;
		left: 0;
		background-color: #61c8f1;
		height: 2px;
		max-width: 80px;
		width: 100%;

		@media #{$query-desktop} {
			max-width: 100px;
		}
	}
}

.detail-body-wrap {
	width: 100%;
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: 2;
}
.inner-wrap {
	max-width: 780px;
	position: relative;
	left: 80px;
	margin: 0 auto;
	padding-bottom: 30px;

	@media #{$query-tablet-large} {
		left: auto;
	}
}

.content {
	// background-color: #464646;
	padding: 20px 0 90px;
	position: relative;
	z-index: 1;
	color: #ffffff;

	@media #{$query-tablet-large} {
		background-color: #464646;
		padding-bottom: 100px;
		padding-left: 66px;
		padding-right: 66px;
	}
	@media #{$query-mobile} {
		padding-bottom: 10px;
		padding-top: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
}
