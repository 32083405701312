@use '../../common/sass/base.module.scss' as *;

.contacts {
    display: flex;
    position: relative;
}

.contacts-contact-item {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 30px;

    @media #{$query-mobile} {
        font-size: 16px;
    }
}

.mc-field-group.form-field-item {
    flex-direction: row;
	align-content: center;
	justify-content: center;
}

.required.mce.form-input {
    font-size: 14px;
	// width: 50%;
	margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
}

.mc-field-group.form-field-item {
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mc-field-wrapper {
    display: flex;
    flex-direction: column;
}

#mc-embedded-subscribe {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
}

.mc-slanted-btn {
    margin-right: 14px;
}

.contact-row {
    display: flex;

    @media #{$query-mobile} {
        display: block;
    }
}

.form {
    flex: 3;
}

.contacts {
    flex: 1;
    padding-left: 40px;

    @media #{$query-mobile} {
        padding-left: 0;
    }
}

//From SecondaryPage.module.scss
.form-input {
    @include GothamNarrow(300);
    width: 100%;
    padding: 14px 12px;
    border: 1px solid #ccc;
    background: #fff;
    color: #000;
    font-family: sans-serif;
    font-size: 18px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
}

.form-text-area {
    @include GothamNarrow(300);
    width: 100%;
    max-width: 100%;
    min-height: 95px;
    border: 1px solid #ccc;
    color: #000;
    font-family: sans-serif;
    font-size: 18px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 14px 12px;
    // margin: 6px 0 4px;
}

.form .is-required .form-input,
.form .is-required .form-text-area,
.form .recaptcha.is-required iframe {
    border: 1px solid #ff0000;
}

.form-fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

.form-fields {
    display: flex;
    margin-bottom: 14px;

    @media #{$query-mobile} {
        &.form-with-column {
            flex-direction: row;

            .form-field-item-50 {
                width: 50%;

                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }
    }

    @media #{$query-tablet} {
        &.form-with-column {
            flex-direction: row;

            .form-field-item-50 {
                width: 50%;

                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }
    }

    @media #{$query-mobile} {
        flex-direction: column;
    }
}

.form-field-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form-field-item-left-padding {
    padding-left: 15px;
}
.form-field-item-50 {
    width: 50%;

    @media #{$query-mobile} {
        width: 100%;
        padding-left: 0;
    }
}
.form-field-item-70 {
    width: 70%;
}

.detail-body-title {
    @include Forza(400);
    font-size: 30px;
    margin: 50px 0 30px;
    font-style: italic;
    position: relative;
    padding-left: 6px;

    @media #{$query-mobile} {
        font-size: 26px;
    }

    &.no-overlap {
        padding-left: 20px;
    }

    &.no-element {
        padding-left: 0;
        font-style: normal;
    }
}

h3.detail-body-title:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -24px;
    width: 5px;
    height: 35px;
    background: #59b6af;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=0 );
    transform: skew(-14deg);

    @media #{$query-mobile} {
        top: -2px;
        left: -10px;
        width: 4px;
        height: 30px;
    }
}

h3.detail-body-title.no-overlap:after {
    left: 0;
}

h3.detail-body-title.no-element:after {
    content: none;
}