@use '../../common/sass/base.module.scss' as *;

// SPORTS NUTRITION PAGE
.sports-nutrition-video {
    max-width: 675px;
    width: 100%;
    aspect-ratio: 16/9;
}

.sports-nutrition-video-vimeo {
    // width: 640px;
    max-width: 675px;
    width: 100%;
    // height: 360px;
    height: 379px;
}

.dr-dana-image {
    width: 50% !important;
    height: 50% !important;
}

.dr-dana-pre-workout-shake {
    max-width: 675px;
    width: 100%;
    height: auto;
}

