@use '../../common/sass/base.module.scss' as *;

// WEEKEND SCHEDULE PAGE
.table {
    tr:nth-child(even) {
        background: rgb(51, 51, 51);
    }

    td:first-child {
        width: 30%;
    }

    td {
        vertical-align: top;
        padding: 10px 5px;
    }

    .weekend-location {
        font-style: italic;
        margin-top: 4px;
        display: block;
        font-size: 15px;
    }
}