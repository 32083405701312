@use '../../common/sass/base.module.scss' as *;

.paragraph {
	font-size: 18px;
	line-height: 1.5;
	margin-top: 1em;
	margin-bottom: 1em;

	@media #{$query-mobile} {
		font-size: 15px;
		line-height: 1.5;
	}
}