@use '../../common/sass/base.module.scss' as *;

.list-title, .list {
	font-size: 18px;
	line-height: 1.5;

	@media #{$query-mobile} {
		font-size: 15px;
		line-height: 1.5;
	}
}

ul.list {
	list-style: none;
	padding-left: 0;

	& li {
		position: relative;
		padding-left: 1.3em;
	}

	& li:before {
		content: '';
		border-radius: 50%;
		width: 9px;
		height: 9px;
		background-color: #6eda93;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		border: 1px solid #62c4a3;
	}

	& li:before,
	& li:after {
		box-sizing: border-box;
		text-rendering: optimizeLegibility;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-text-size-adjust: none;
		outline: none;
	}

	ul {
		list-style: none;
		padding-left: 24px;

		& li:before {
			background-color: transparent;
			border: 2px solid #62c4a3;
		}
	}
}

.list li {
	margin: 7px 0;
}