@use '../../common/sass/base.module.scss' as *;

.Button {
	min-width: 180px;
	height: 60px;
	border: 4px solid #00e1d6;
	transform: skewX(-10deg);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 0 10px;
}

.label {
	@include Forza(500);
	transform: skewX(10deg);
	display: inline-block;
	text-transform: uppercase;
	font-size: 14px;
	color: white;
	letter-spacing: 2px;
}

.main-background {
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.background {
	background-color: #00e1d6;
	position: absolute;
	width: calc(100% - 3px);
	height: calc(100% - 3px);
	top: 1.5px;
	left: 1.5px;
	transform: scale(0);
	transition: transform 0.3s ease; //temporary, will use react-spring
	z-index: -1;
}

.Button:hover {
	.background {
		transform: scale(1);
	}
}

.Button.small {
	height: 46px;
}

.Button.fill {
	background-color: #4eb799;
	font-weight: 400;

	.label {
		font-size: 25px;
	}
}
