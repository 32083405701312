@use '../../common/sass/base.module.scss' as *;

.SponsorTickerMobile {
	height: 50px;
	width: 100%;
	position: relative;
	margin-top: 30px;
}

.header {
	@include Gotham(700);
	background-color: #1f5675;
	width: 100%;
	height: 40px;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
	line-height: 40px;
	font-size: 14px;
	color: #5faac8;
	letter-spacing: 2px;
}

.sponsors {
	position: relative;
}

.sponsor {
	text-align: center;
	width: 100%;
	position: absolute;
	height: 100%;
}

.img {
	height: 80px;
	width: auto;
	padding: 20px;
	margin: auto;
	display: block;
	max-width: 160px;
}
