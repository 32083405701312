@use '../../common/sass/base.module.scss' as *;

:global {
	/* Video gallery style */
	.photo-gallery-video {
		max-width: 880px;
		margin-left: auto;
		margin-right: auto;

		@media #{$query-mobile} {
			max-width: 420px;
		}

		@media #{$query-mobile} {
			max-width: 200px;
		}

		@media #{$query-tablet} {
			max-width: 640px;
		}
	}
	.photo-gallery-video-inner {
		padding: 56.25% 0 0 0;
		position: relative;
	}
	.photo-gallery-video-frame {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	/* Video gallery style */

	.PhotoGrid {
		margin: 0 auto;
	}

	.grid {
		position: relative;
		margin: 0 auto;
	}

	.PhotoGrid .grid::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		pointer-events: none;
		// background: #252323 url(../img/loading.svg) no-repeat 50% 75px;
		background-size: 60px auto;
		-webkit-transition: opacity 0.3s;
		transition: opacity 0.3s;
	}

	.PhotoGrid .grid--loaded::after {
		opacity: 0;
	}

	.grid__item {
		width: 260px;
		padding: 10px;
	}

	.grid__item--current {
		opacity: 0 !important;
	}

	.img-wrap {
		display: block;
	}

	.img-wrap:focus,
	.img-wrap:hover {
		outline: none;
	}

	.img-wrap img {
		display: block;
		max-width: 100%;
	}

	.preview {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		display: -ms-flex;
		display: -webkit-flex;
		display: flex;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-ms-flex-line-pack: center;
		-webkit-align-content: center;
		align-content: center;
		-ms-flex-align: start;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	.preview::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		background: #1f1d1d;
		-webkit-transition: opacity 0.6s;
		transition: opacity 0.6s;
	}

	.preview--open {
		pointer-events: auto;
	}

	.preview--open::before {
		opacity: 1;
	}

	.clone {
		position: fixed;
		z-index: 110;
		transition: transform 0.5s;
		backface-visibility: hidden;
	}

	.original {
		position: relative;
		z-index: 120;
		display: block;
		margin: auto 0;
		object-fit: contain;
		transition: opacity 0.2s;
		backface-visibility: hidden;
		padding: 30px;
	}

	.preview--open .animate {
		/* open */
		-webkit-transition: -webkit-transform 0.6s, opacity 0.2s;
		transition: transform 0.6s, opacity 0.2s;
	}

	.animate {
		/* close */
		-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
		transition: transform 0.3s, opacity 0.2s;
	}

	.description {
		color: #fff;
	}

	.photo-grid .description--grid {
		display: none;
	}

	.description--preview {
		font-size: 2em;
		font-size: 1.5em;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 140;
		display: -ms-flex;
		display: -webkit-flex;
		display: flex;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		width: 100%;
		height: 25vh;
		text-align: center;
		padding: 1em 3vw;
		opacity: 0;
		-webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
		transition: opacity 0.8s, transform 0.8s;
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		-webkit-transform: translate3d(100px, 0, 0);
		transform: translate3d(100px, 0, 0);
	}

	.preview--open .description--preview {
		opacity: 1;
		-webkit-transition-delay: 0.2s;
		transition-delay: 0.2s;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	/* Close button */

	.action {
		font-size: 1.5em;
		margin: 0;
		padding: 0;
		cursor: pointer;
		vertical-align: top;
		color: #6bd68a;
		border: none;
		background: none;
	}

	.action:hover,
	.action:focus {
		color: #6bd68a;
		outline: none;
	}

	.action--close {
		position: fixed;
		z-index: 150;
		top: 15px;
		right: 15px;
		opacity: 0;
		-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
		transition: opacity 0.3s, transform 0.3s;
		-webkit-transform: scale3d(0.6, 0.6, 1);
		transform: scale3d(0.6, 0.6, 1);
		width: 40px;
	}

	.action--close svg {
		fill: $mint-green;
		width: 100%;
	}

	.preview--image-loaded .action--close {
		opacity: 1;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	.text-hidden {
		position: absolute;
		display: block;
		overflow: hidden;
		width: 0;
		height: 0;
		color: transparent;
	}

	.button {
		font-size: 0.5em;
		font-weight: bold;
		margin: 0;
		padding: 1em 2em;
		color: #fff;
		border: 2px solid #fff;
		border-radius: 2em;
		background: none;
	}

	.button--view {
		margin-top: 2em;
	}
}
