@use '../../common/sass/base.module.scss' as *;

$skew: 10deg;

.SlantedButton {
	transform: skewX(-$skew);
	position: relative;
	overflow: hidden;
	width: 170px;

	@media #{$query-mobile} {
		display: block;
		margin: 0 auto;
	}
}
.slanted-button-item {
	width: 170px;
	height: 56px;
	background-size: 170px 56px;
	visibility: visible;
	pointer-events: auto;
	left: 50%;
	margin-left: -85px;
}
.slanted-button-outline {
	width: 170px;
	height: 56px;
	background-size: 170px 56px;
}

.slanted-button-line1 {
	margin-left: 50%;
	width: 170px;
	height: 3px;
	background: rgba(255, 255, 255, 0.2);
	transform: scaleX(1);
	// transition: -webkit-transform 1000ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
}

.slanted-button-inner1 {
	width: 100%;
	height: 100%;
	background-color: rgb(255, 255, 255);
	transform: scaleX(0);
	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.slanted-button-line2 {
	position: absolute;
	width: 3px;
	height: 50px;
	background: rgba(255, 255, 255, 0.2);
	top: 3px;
	left: 0px;
	transform-origin: 50% 0% 0px;
	transform: scaleY(1);
	transition: transform 1000ms cubic-bezier(0.77, 0, 0.175, 1) 0ms, background-color 0.2s ease;
}
.slanted-button-inner2 {
	width: 100%;
	height: 100%;
	background-color: rgb(255, 255, 255);
	transform: scaleY(0);
	transform-origin: 50% 0% 0px;
	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.slanted-button-line3 {
	position: absolute;
	width: 3px;
	height: 50px;
	background: rgba(255, 255, 255, 0.2);
	top: 3px;
	right: 0px;
	transform-origin: 50% 0% 0px;
	transform: scaleY(1);
	// transition: -webkit-transform 1000ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
}
.slanted-button-inner3 {
	width: 100%;
	height: 100%;
	background-color: rgb(255, 255, 255);
	transform: scaleY(0);
	transform-origin: 50% 0% 0px;
	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.slanted-button-line4 {
	position: absolute;
	width: 85px;
	height: 3px;
	background: rgba(255, 255, 255, 0.2);
	bottom: 0px;
	left: 0px;
	transform-origin: 0% 50% 0px;
	transform: scaleX(1);
	// transition: -webkit-transform 1000ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
}
.slanted-button-inner4 {
	width: 100%;
	height: 100%;
	background-color: rgb(255, 255, 255);
	transform: scaleX(0);
	transform-origin: 0% 50% 0px;
	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.slanted-button-line5 {
	position: absolute;
	width: 85px;
	height: 6px;
	// background: rgba(255, 255, 255, 0.2);
	bottom: 0px;
	right: 0px;
	transform-origin: 100% 50% 0px;
	transform: scaleX(1);
	// transition: -webkit-transform 1000ms cubic-bezier(0.77, 0, 0.175, 1) 0ms;
}
.slanted-button-inner5 {
	width: 100%;
	height: 100%;
	// background-color: rgb(255, 255, 255);
	transform: scaleX(0);
	transform-origin: 100% 50% 0px;
	transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
	background: rgb(98, 187, 173);
	background: -moz-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: linear-gradient(to right, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=1 );
}

.slanted-button-text {
	@include Forza(500);
	text-transform: uppercase;
	transform: skewX($skew);
}

.slanted-button-text1 {
	position: absolute;
	font-size: 16px;
	color: rgb(255, 255, 255);
	width: 100%;
	top: 50%;
	left: 0%;
	text-align: center;
	letter-spacing: 3px;
	margin-top: -5px;
	line-height: 11.2px;
	opacity: 1;
	transition: opacity 1000ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0ms, color 0.15s ease;
}

.slanted-button-text2 {
	position: absolute;
	font-size: 16px;
	color: rgb(0, 0, 0);
	width: 100%;
	top: 50%;
	left: 0%;
	text-align: center;
	letter-spacing: 3px;
	margin-top: -5px;
	line-height: 11.2px;
	opacity: 0;
	transition: opacity 500ms cubic-bezier(0.39, 0.575, 0.565, 1) 0ms;
}

.slanted-button-bg {
	position: absolute;
	width: 162px;
	height: 48px;
	background-size: 162px 48px;
	top: 4px;
	left: 4px;
	opacity: 0;
	transform: scaleX(0) scaleY(0);
	background-color: rgb(255, 255, 255);
	transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms,
		-webkit-transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0ms;
}

.slanted-button-hit {
	width: 100%;
	height: 100%;
	z-index: 99999;
	top: 0px;
	left: 0px;
	position: absolute;
	cursor: pointer;
}

.SlantedButton:hover {
	.slanted-button-inner1,
	.slanted-button-inner2,
	.slanted-button-inner3,
	.slanted-button-inner4,
	.slanted-button-inner5 {
		transform: scaleX(1);
	}

	.slanted-button-bg {
		opacity: 1;
		transform: scaleX(1) scaleY(1);
	}

	.slanted-button-text1 {
		opacity: 0;
	}
	.slanted-button-text2 {
		opacity: 1;
	}
}

/*------- Gradient Theme ---------*/
.SlantedButton.gradient .slanted-button-line1 {
	background: rgba(255, 255, 255, 0.2);
	height: 6px;
}

.SlantedButton.gradient .slanted-button-line2 {
	background: rgb(98, 187, 173);
	background: -moz-linear-gradient(top, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: linear-gradient(to bottom, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=0 );
	width: 6px;
	height: 56px;
	top: 0px;
}

.SlantedButton.gradient .slanted-button-line3 {
	background: rgba(255, 255, 255, 0.2);
	width: 6px;
	height: 44px;
	top: 6px;
}

.SlantedButton.gradient .slanted-button-line4 {
	background: rgba(255, 255, 255, 0.2);
	width: 85px;
	height: 6px;
}

.SlantedButton.gradient .slanted-button-inner1 {
	/*background-color: rgb(255, 255, 255);*/
	background: rgb(98, 187, 173);
	background: -moz-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: linear-gradient(to right, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=1 );
}

.SlantedButton.gradient .slanted-button-inner2 {
	background-color: #62bbad;
}

.SlantedButton.gradient .slanted-button-inner3 {
	background-color: #c1e38f;
}

.SlantedButton.gradient .slanted-button-inner4 {
	background: rgb(98, 187, 173);
	background: -moz-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: -webkit-linear-gradient(left, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	background: linear-gradient(to right, rgba(98, 187, 173, 1) 0%, rgba(193, 227, 143, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=1 );
}

.SlantedButton.gradient .slanted-button-text1 {
	color: rgb(0, 0, 0);
}

.blog-sigle .SlantedButton.gradient .slanted-button-text1 {
	color: rgb(0, 0, 0);
}

.blog-sigle.main-menu--active .SlantedButton.gradient .slanted-button-text1 {
	color: rgb(255, 255, 255);
}

.SlantedButton.gradient .slanted-button-text2 {
	color: rgb(255, 255, 255);
}

.SlantedButton.gradient .slanted-button-bg {
	background-color: transparent;
}

.SlantedButton.gradient:hover {
	.slanted-button-inner1,
	.slanted-button-inner2,
	.slanted-button-inner3,
	.slanted-button-inner4,
	.slanted-button-inner5 {
		transform: scaleX(1);
	}
}

.SlantedButton.gradient .slanted-button-text1 {
	opacity: 1;
	color: #fff;
}
.SlantedButton.gradient .slanted-button-text2 {
	opacity: 0;
}

.slanted-button-outline::before {
	content: '';
	position: absolute;
	width: 162px;
	height: 48px;
	background-size: 162px 48px;
	top: 4px;
	left: 4px;
	opacity: 0.1;
	transform: translateX(0) scaleX(0);
	transform-origin: left;
	background-color: #36b1d6;
	animation: rbuttonPulse 4s ease infinite;
}

@keyframes rbuttonPulse {
	0% {
		-webkit-transform: translateX(0) scaleX(0);
		transform: translateX(0) scaleX(0);
		opacity: 0.1;
	}
	69% {
		-webkit-transform: translateX(0) scaleX(0);
		transform: translateX(0) scaleX(0);
		opacity: 0.1;
	}
	82% {
		-webkit-transform: translateX(45px) scaleX(0.4);
		transform: translateX(45px) scaleX(0.4);
		opacity: 0.25;
	}
	100% {
		-webkit-transform: translateX(270px) scaleX(0.2);
		transform: translateX(270px) scaleX(0.2);
		opacity: 0;
	}
}
