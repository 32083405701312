@use '../../../common/sass/base.module.scss' as *;

.main-menu-social-list {
	position: absolute;
	z-index: 8;
	top: 106px;
	left: 22px;
	display: flex;

	@media #{$query-tablet-large} {
		position: static;
	}
}

.main-menu-social-item {
	display: inline-block;
	height: 20px;
	margin-right: 13px;
	opacity: 0.6;
	transition: 0.15s opacity ease;

	&:hover {
		opacity: 1;
	}
}

.main-menu-social-item-youtube svg {
	width: 30px;
}

.main-menu-social-item-svg {
	width: 20px;
	height: 20px;
	fill: #ffffff;
	transition: 0.15s fill ease;
}

.main-menu-social-list.is-dark .main-menu-social-item-svg,
.blog-sigle .main-menu-social-list .main-menu-social-item-svg {
	fill: #969696;
}

.main-menu--active .is-dark .main-menu-social-item-svg {
	fill: #ffffff;
}
