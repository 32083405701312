@use '../../common/sass/base.module.scss' as *;

.ContentPageBackground {
	position: relative;
}

.header {
	width: 100%;
	height: 100%;
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 133%;
	background-image: linear-gradient(90deg, rgb(14, 100, 135, 0.96) 450px, rgba(0, 0, 0, 0.15) 1010px);

	@media #{$query-tablet-large} {
		background-image: linear-gradient(90deg, rgba(14, 100, 135, 0.9) 210px, rgba(0, 0, 0, 0.15) 690px);
	}
}

.img {
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
}

.body {
	width: 100%;
	height: 100%;
	background-color: #464646;
	position: relative;
	z-index: 2;

	&:before {
		content: '';
		width: 100%;
		background-color: #464646;
		height: 135px;
		display: block;
		transform: skewY(2deg);
		position: absolute;
		top: -23px;

		@media #{$query-mobile} {
			content: none;
		}
	}
}

.elements {
	position: absolute;
	top: 0;
	left: 0;
}

.cyclist {
	position: absolute;
	top: calc(-158px - 1vw); // Accounts for the shifting diagonal based on width
	left: 645px;
	pointer-events: none;
	width: 160px;
	max-width: unset;

	@media #{$query-mobile} {
		display: none;
	}
}

.cyclist-back {
	position: absolute;
	top: calc(-128px - 1vw); // Accounts for the shifting diagonal based on width
	left: 430px;
	pointer-events: none;
	width: 103px;
	display: block;
	max-width: unset;

	@media #{$query-mobile} {
		display: none;
	}
}
