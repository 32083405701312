.OverlayBase {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-color: rgba(0, 0, 0, 0.65);
}

// States

.is-silent {
	background-color: transparent !important;
	backdrop-filter: none !important;
}
