@use '../../common/sass/base.module.scss';

.HomeBG {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.image {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
}

.grad {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(rgba(#135a85, 0) 0%, rgba(#3188bd, 1) 55%);
}

//#135A85
