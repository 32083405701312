@use '../../common//sass/base.module.scss' as *;

.back {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: white;
	position: absolute;
	right: 40px;
	top: 24px;
	z-index: 2;
	box-shadow: 0px 0px 7px rgb(0 0 0 / 43%);

	@media #{$query-tablet-large} {
		top: 20px;
		right: 20px;
	}
	@media #{$query-mobile} {
		top: 14px;
		right: 20px;
	}
}