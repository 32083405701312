@use '../../common/sass/base.module.scss' as *;

.BlogContent {
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	touch-action: pan-y;
}

.header {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
}

.hero-img {
	background: no-repeat center center;
	background-size: cover;
	height: 125%;
	width: 100%;
	position: absolute;
	top: -10vh;
	will-change: transform;
}

.detail-head-wrap {
	display: block;
	position: relative;
	width: 100%;
	max-width: 790px;
	padding-left: 310px;
	padding-right: 80px;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media #{$query-tablet-large} {
		padding: 66px;
	}
	@media #{$query-mobile} {
		padding: 30px;
	}
}

.detail-head-wrap.blog {
	max-width: 930px;

	@media #{$query-tablet-large} {
		max-width: 960px + 86px;
		margin: auto;
		width: 100%;
	}
	@media #{$query-mobile} {
		max-width: none;
		width: 100%;
		padding-right: 30px;
	}
}

.blog .detail-head-title {
	@include Gotham(700);
	font-size: 40px;
	padding-left: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	text-transform: none;
	margin-bottom: 5px;
	max-width: 580px;

	@media #{$query-mobile} {
		font-size: 23px;
	}
}

.blog .detail-head-sub-title {
	background-color: #47d0c9;
	color: #fff;
	display: inline-block;
	margin-bottom: 15px;
	padding: 5px 23px;
	@include Forza(400);
	transform: skewX(-14deg);
	font-size: 18px;

	text-shadow: 0px 4px 16px black;

	span {
		transform: skewX(14deg);
		display: inline-block;
	}
}

.blog {
	max-width: 780px;
}

.blog .detail-head-title:after {
	content: none;
}

.info-item {
	@include GothamNarrow(300);
	font-size: 18px;
	display: flex;
	align-items: center;
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}

	svg {
		margin-right: 10px;
	}

	@media #{$query-mobile} {
		font-size: 12px;
	}
}

.info {
	display: flex;
	align-items: center;
	color: #4b4b4b;
}

.detail-head-title {
	@include Forza(500);
	font-size: 67px;
	color: rgb(255, 255, 255);
	margin: 0 auto;
	margin-left: 0;
	position: relative;
	min-width: 268px;
	padding-bottom: 22px;
	margin-bottom: 22px;
	line-height: 1.1;

	@media #{$query-mobile} {
		font-size: 50px;
		width: 100%;
	}
	@media #{$query-tablet} {
		font-size: 60px;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #61c8f1;
		height: 2px;
		max-width: 80px;
		width: 100%;
	}
}

.summary-copy {
	@include Gotham(300);
	font-size: 18px;
	line-height: 1.5;
	position: relative;
	z-index: 2;
	color: white;
	min-width: 268px;

	@media #{$query-mobile} {
		font-size: 16px;
		line-height: 22px;
		// width: calc(100% - 40px);
		transform: translateY(0);
		min-width: auto;
	}
	@media #{$query-tablet} {
		font-size: 20px;
	}
}

.summary-copy.info {
	color: #fff;
	margin-bottom: 35px;
}

.blog-list-column {
	display: flex;
	margin-left: -30px;
	margin-right: -30px;

	@media #{$query-tablet} {
		margin-left: -20px;
		margin-right: -20px;
	}
}

.detail-body-wrap {
	width: 100%;
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: 2;
}

.inner-wrap {
	max-width: 960px;
	position: relative;
	left: 80px;
	margin: 0 auto;
	padding-bottom: 30px;

	@media #{$query-tablet-large} {
		left: auto;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.content {
	padding: 20px 0 90px;
	position: relative;
	z-index: 1;
	color: #ffffff;

	@media #{$query-tablet-large} {
		background-color: #595a5a;
		padding-bottom: 0;
	}

	&.hide-slant:before {
		content: none;
	}
}

.content-elements {
	position: absolute;
	top: 0;
	left: 0;
}

.element-cyclist {
	position: absolute;
	top: calc(-158px - 1vw); // Accounts for the shifting diagonal based on width
	left: 645px;
	pointer-events: none;
	width: 160px;
	max-width: unset;

	@media #{$query-mobile} {
		display: none;
	}
}

.element-cyclist-back {
	position: absolute;
	top: calc(-128px - 1vw); // Accounts for the shifting diagonal based on width
	left: 430px;
	pointer-events: none;
	width: 103px;
	display: block;
	max-width: unset;

	@media #{$query-mobile} {
		display: none;
	}
}

.overlap-header {
	margin-top: -100px;

	@media #{$query-mobile} {
		margin-top: -70px;
	}
}

/** BLOG HEADER **/

.info-item {
	@include GothamNarrow(300);
	font-size: 18px;
	display: flex;
	align-items: center;
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}

	svg {
		margin-right: 10px;
	}

	@media #{$query-mobile} {
		font-size: 12px;
	}
}

.info {
	display: flex;
	align-items: center;
	color: #4b4b4b;
}
