@use '../../../common/sass/base.module.scss' as *;

.MainMenu {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 3;
	pointer-events: auto;
}

.main-menu--active {
	pointer-events: auto;
}

.list {
	display: flex;
	width: 160px;
	flex-direction: column;
	justify-content: center;
	position: relative;
	left: -10px;
}

.main-menu--active .is-dark .main-menu-item-label,
.main-menu--active.blog .main-menu-item-label {
	color: rgb(255, 255, 255);
}

.rbutton {
	position: absolute;
	z-index: 2;
	top: 30px;
	left: 25px;
	// transform: scale(1.1) skewX(14deg) translateX(10px);
	transform: scale(1.1);

	@media #{$query-tablet-large} {
		position: fixed;
	}
}

//.MainMenu:not(.main-menu--active) .rbutton .button-element-outline:before
.rbutton .button-element-outline:before {
	content: '';
	position: absolute;
	width: 162px;
	height: 48px;
	background-size: 162px 48px;
	top: 4px;
	left: 4px;
	opacity: 0.1;
	transform: translateX(0) scaleX(0);
	transform-origin: left;
	background-color: rgb(54, 177, 214);
	animation: rbuttonPulse 4s ease infinite;
}

.Policy {
	position: absolute;
	bottom: 70px;
	left: 15px;
}

@keyframes rbuttonPulse {
	0% {
		transform: translateX(0) scaleX(0);
		opacity: 0.1;
	}
	69% {
		transform: translateX(0) scaleX(0);
		opacity: 0.1;
	}
	82% {
		transform: translateX(45px) scaleX(0.4);
		opacity: 0.25;
	}
	100% {
		transform: translateX(270px) scaleX(0.2);
		opacity: 0;
	}
}

@keyframes menuItemSubMenu {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.3;
	}
}

@media #{$query-tablet-large} {
	.MainMenu {
		overflow-y: auto;
		display: flex;

		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 0; /* Remove scrollbar space */
			background: transparent; /* Optional: just make scrollbar invisible */
		}
	}

	.main-menu-content {
		display: flex;
		flex-direction: column;

		//Margin auto was used instead of justify-content to align vertical, to avoid the overflow-y issue.
		margin-top: auto;
		margin-bottom: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.list {
		order: 1;
		margin: 20px 0 40px;
	}

	.SocialMedia {
		order: 2;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	.Policy {
		position: static;
		order: 3;
		margin-left: 20px;
	}

	.rbutton {
		position: relative;
		top: 0;
	}
}
