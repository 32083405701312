/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-square-spin,
.la-square-spin > div {
	position: relative;
	box-sizing: border-box;
}
.la-square-spin {
	display: block;
	font-size: 0;
	color: #fff;
}
.la-square-spin.la-dark {
	color: #333;
}
.la-square-spin > div {
	display: inline-block;
	float: none;
	background-color: currentColor;
	border: 0 solid currentColor;
}
.la-square-spin {
	width: 32px;
	height: 32px;
}
.la-square-spin > div {
	width: 100%;
	height: 100%;
	border-radius: 0;
	animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}
.la-square-spin.la-sm {
	width: 16px;
	height: 16px;
}
.la-square-spin.la-2x {
	width: 64px;
	height: 64px;
}
.la-square-spin.la-3x {
	width: 96px;
	height: 96px;
}
/*
  * Animation
  */

@keyframes square-spin {
	0% {
		transform: perspective(100px) rotateX(0) rotateY(0);
	}
	25% {
		transform: perspective(100px) rotateX(180deg) rotateY(0);
	}
	50% {
		transform: perspective(100px) rotateX(180deg) rotateY(180deg);
	}
	75% {
		transform: perspective(100px) rotateX(0) rotateY(180deg);
	}
	100% {
		transform: perspective(100px) rotateX(0) rotateY(360deg);
	}
}
