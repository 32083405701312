@use '../../common/sass/base.module.scss' as *;

$size: 66px;

:export {
	size: strip-unit($size);
}

.MenuButton {
	position: fixed;
	top: 0;
	left: 0;
	transform-origin: top left;
	pointer-events: auto;
	z-index: 2;
}

.menu-btn {
	&:focus {
		outline: none;
	}

	position: absolute;
	display: inline-block;
	background: transparent;
	width: 34px;
	padding: 0;
	margin-top: 20px;
	margin-left: 15px;
	border: none;
	transition: opacity 0.3s ease;
	cursor: pointer;
}

.menu-btn-bg {
	content: '';
	position: absolute;
	top: 0px;
	left: 0px;
	width: $size;
	height: $size;
	background-color: #1278a2;
	z-index: -1;
	opacity: 1;
	box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.43);
	pointer-events: none;

	transition: opacity 250ms linear;
	will-change: opacity;
}

.menu-btn-bg.menu-open {
	opacity: 0;
}

.menu-btn-icon {
	width: 100%;
}

.menu-btn-icon-burger {
	margin-top: 5px;

	.cls-1 {
		fill: none;
		stroke: #fff;
		stroke-width: 2px;
	}
}

.menu-btn-icon-close {
	max-width: 27px;
	.cls-1 {
		fill: none;
		stroke: #fff;
		stroke-width: 5px;
	}
}

.menu-btn-icon-back {
	max-width: 27px;

	.cls-1,
	.cls-2 {
		fill: #fff;
	}

	.cls-1 {
		@include GothamNarrow(400);
		font-size: 10px;
	}
}

.menu-icon-container {
	position: fixed;
	left: 0;
	width: $size;
	height: $size;
	cursor: pointer;
}