@use '../../common/sass/base.module.scss' as *;

.title {
	@include Forza(400);
	font-size: 30px;
	margin: 50px 0 30px;
	font-style: italic;
	position: relative;
	padding-left: 6px;

	@media #{$query-mobile} {
		font-size: 26px;
	}

	&.no-overlap {
		padding-left: 20px;
	}

	&.no-element {
		padding-left: 0;
		font-style: normal;
	}
}

h3.title:after {
	content: '';
	position: absolute;
	top: -2px;
	left: -24px;
	width: 5px;
	height: 35px;
	background: #59b6af;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=0 );
	transform: skew(-14deg);

	@media #{$query-mobile} {
		top: -2px;
		left: -10px;
		width: 4px;
		height: 30px;
	}
}
