@use '../../common/sass/base.module.scss' as *;

.Subscribe {
	margin-top: 20px;
}

.signup {
	min-height: 300px;
}

.mc-embed-title {
	@include Forza();
	color: white;
}

.mc-field-wrapper {
	display: flex;
	flex-direction: column;
}

.Spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
