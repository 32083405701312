@use '../../common/sass/base.module.scss' as *;

.Profile {
	display: flex;
    margin-top: 40px;
}

.Image {
	height: 250px;
    width: 250px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
}

.Name {
	@include GothamNarrow(400);
    font-style: normal;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 7px;
}

.Link {
	background-color: #353535;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.Description p {
	margin-top: 7px;
    font-size: 18px;
    line-height: 1.5;
}