@use '../../common/sass/base.module.scss' as *;

.subtitle {
	@include GothamNarrow(400);
	font-size: 24px;
	margin: 1.33em 0 7px;

	@media #{$query-mobile} {
		font-size: 20px;
		margin: 1em 0 7px;
	}
}