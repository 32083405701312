@use '../../../common/sass/base.module.scss' as *;

.sub-menu-item {
	@include Forza(400);

	padding-top: 9px;
	padding-bottom: 9px;

	position: relative;
	display: flex;
	align-items: center;

	font-size: 20px;
	text-decoration: none;
}

.sub-menu-item--disabled {
	pointer-events: none;
}

.sub-menu-item-label {
	color: rgb(255, 255, 255);
	opacity: 0.65;
	@include defaultEase();
	position: relative;
	display: inline-block;
}

a.sub-menu-item-label {
	color: rgb(255, 255, 255);
	@include defaultEase();
}

.sub-menu-label-coming-soon {
	font-size: 17px;
}

.sub-menu-item--disabled .sub-menu-item-label {
	opacity: 0.3;
}

.sub-menu-item--hovered .sub-menu-item-label {
	opacity: 1;
}

.sub-menu--active .sub-menu-item {
	pointer-events: auto;
}

.sub-menu-item--hovered .sub-menu-item-label {
	transform: translateX(5px);
}

.sub-menu-dash-line {
	left: -35px;
	// width: 22px;
	margin-right: 10px;
	@include defaultEase();
	transition: 0.3s transform, 0.3s opacity, 0.3s width;
	opacity: 0.3;
}

.sub-menu-item--hovered .sub-menu-dash-line {
	transform: translateX(0px) scale(2.4, 1);
	// width: 50px;
	opacity: 1;
}

.sub-menu-dash-line line {
	stroke: white;
}
