@use '../../common/sass/base.module.scss' as *;

.item {
	border-radius: 6px;
	color: #666;
	overflow: hidden;
	flex-basis: calc(50% - 20px);
	margin-bottom: 40px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
	background-color: white;
	max-width: 450px;
	background-color: rgb(66, 66, 66);

	@media #{$query-mobile} {
		flex-basis: 100%;
		margin-bottom: 20px;
	}
}

.image-crop {
	height: 277px;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
	overflow: hidden;

	@media #{$query-mobile} {
		height: 192px;
	}
}

.image {
	background-size: cover;
	background-position: center;
	height: 100%;
	width: 100%;
	transition: transform 0.3s ease;
}

.item:hover .image {
	transform: scale(1.1);
}

.item-info {
	padding: 15px 15px 20px;
	background-color: white;
}

.item h2 {
	font-size: 20px;
	line-height: 1.2;
	color: #505050;
	font-weight: normal;
	@include Forza(500);
	margin-top: 10px;
	margin-bottom: 0;
	transition: color 0.3s ease;
	position: relative;
}

.item:hover h2 {
	color: rgb(0, 0, 0);
}

.item h2:before {
	content: '';
	position: absolute;
	left: -10px;
	top: 0px;
	height: 100%;
	width: 3px;
	background-color: #00e1d6;
	transform: scaleY(0);
	transition: transform 0.3s ease;
}

.item:hover h2:before {
	transform: scaleY(1);
}

.meta-date {
	@include GothamNarrow(400);
	text-transform: uppercase;
	color: rgb(160, 160, 160);
	font-size: 12px;
}

.meta-author {
	@include Forza(500);
	color: rgb(80, 80, 80);
	font-size: 14px;
}
