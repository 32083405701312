@use '../../../common/sass/base.module.scss' as *;

.MainMenuBg {
	position: absolute;
	top: 0;
	left: -200px;
	pointer-events: none;
	height: 100%;
	width: 500px;
	z-index: -1;
	overflow: hidden;
	// background-color: #06314d;
	// opacity: 0.9;
	transform: skewX(-7deg);
	will-change: transform;

	@media #{$query-tablet-large} {
		z-index: 2;
	}
}
