@use '../../common/sass/base.module.scss' as *;

.detail-body-text {
	font-size: 18px;
	line-height: 1.5;

	@media #{$query-mobile} {
		font-size: 15px;
		line-height: 1.5;
	}
}

.detail-footer .detail-body-text.sp-highlight {
	position: relative;
	height: 100%;
	// display: flex;
	align-items: center;
	overflow: visible;
	@include Gotham(300);
	a {
		color: #126c91;
	}
}

ul.detail-body-text {
	list-style: none;
	padding-left: 0;

	& li {
		position: relative;
		padding-left: 1.3em;
	}

	& li:before {
		content: '';
		border-radius: 50%;
		width: 9px;
		height: 9px;
		background-color: #6eda93;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		border: 1px solid #62c4a3;
	}

	& li:before,
	& li:after {
		box-sizing: border-box;
		text-rendering: optimizeLegibility;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-text-size-adjust: none;
		outline: none;
	}

	ul {
		list-style: none;
		padding-left: 24px;

		& li:before {
			background-color: transparent;
			border: 2px solid #62c4a3;
		}
	}
}

.detail-body-text li {
	margin: 7px 0;
}

h3.detail-body-title:after {
	content: '';
	position: absolute;
	top: -2px;
	left: -24px;
	width: 5px;
	height: 35px;
	background: #59b6af;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62bbad', endColorstr='#c1e38f',GradientType=0 );
	transform: skew(-14deg);

	@media #{$query-mobile} {
		top: -2px;
		left: -10px;
		width: 4px;
		height: 30px;
	}
}

.detail-body-title {
	@include Forza(400);
	font-size: 30px;
	margin: 50px 0 30px;
	font-style: italic;
	position: relative;
	padding-left: 6px;

	@media #{$query-mobile} {
		font-size: 26px;
	}

	&.no-overlap {
		padding-left: 20px;
	}

	&.no-element {
		padding-left: 0;
		font-style: normal;
	}
}

.detail-body-text {
	font-size: 18px;
	line-height: 1.5;
	margin-top: 1em;
	margin-bottom: 1em;

	@media #{$query-mobile} {
		font-size: 15px;
		line-height: 1.5;
	}
}

.detail-body-subtitle {
	@include GothamNarrow(400);
	font-size: 24px;
	margin: 1.33em 0 7px;

	@media #{$query-mobile} {
		font-size: 20px;
		margin: 1em 0 7px;
	}
}


.detail-body-infobox {
	background-color: #353535;
	border-radius: 3px;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 1.5;
}

.detail-body-infobox + p {
	margin-top: 7px;
}

.detail-footer .detail-body-text.sp-highlight {
	position: relative;
	height: 100%;
	// display: flex;
	align-items: center;
	overflow: visible;
	@include Gotham(300);
	a {
		color: #126c91;
	}
}

ul.detail-body-text {
	list-style: none;
	padding-left: 0;

	& li {
		position: relative;
		padding-left: 1.3em;
	}

	& li:before {
		content: '';
		border-radius: 50%;
		width: 9px;
		height: 9px;
		background-color: #6eda93;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		border: 1px solid #62c4a3;
	}
}