@use '../../common/sass/base.module.scss' as *;

.BlogBackground {
	position: relative;
}

.header {
	width: 100%;
	height: 100%;
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 133%;
	background-image: linear-gradient(90deg, rgb(14, 100, 135, 0.96) 400px, rgba(0, 0, 0, 0.15) 900px);

	@media #{$query-tablet-large} {
		background-image: linear-gradient(90deg, rgba(14, 100, 135, 0.9) 210px, rgba(0, 0, 0, 0.15) 690px);
	}
}

.img {
	top: 0;
	left: 0;
	height: 133%;
	width: 100%;
	background-position: center;
	background-size: cover;
}

.body {
	width: 100%;
	height: 100%;
	background-color: #464646;
	position: relative;
	z-index: 2;
}
