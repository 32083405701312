@use '../../../common/sass/base.module.scss' as *;

.SubMenu {
	position: relative;
	width: 100%;
	max-width: 405px;
	overflow: hidden;
	z-index: 4;
	height: 100%;
}

.sub-menu-box {
	pointer-events: auto;
	position: absolute;
	height: 100%;
	width: 407px;
	overflow: hidden;
}

.sub-menu-inner {
	display: flex;
	height: 85%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: absolute;
	width: 100%;

	@media #{$query-tablet-large} {
		left: 20px;
	}
}

.sub-menu-inner-in .sub-menu-items {
	pointer-events: auto;
}

.sub-menu-items {
	position: relative;
}

.SubMenuTitle {
	position: relative;
	top: 0;
	left: 0;
	width: 396px;
	height: auto;
	margin: 0;
	overflow: hidden;
}

.sub-menu-title {
	@include Forza(300);
	font-size: 80px;
	position: relative;
	width: 396px;
	margin: 0px;
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	color: white;
	overflow: hidden;

	@media #{$query-tablet-large} {
		font-size: 60px;
		margin-bottom: 10px;
	}
	@media #{$query-mobile} {
		font-size: 30px;
		margin-bottom: 10px;
	}
}

.sub-menu-bg-svg {
	position: absolute;
	top: -125px;
	backdrop-filter: blur(30px);
	background-color: rgba(6, 107, 148, 0.5);
	box-shadow: 0 85px 45px rgba(0, 0, 0, 0.8);
}

.sub-menu-dash-line {
	left: -35px;
	margin-right: 10px;
	@include defaultEase();
	transition: 0.3s transform, 0.3s opacity, 0.3s width;
	opacity: 0.3;
}

.sub-menu-item--hovered .sub-menu-dash-line {
	transform: translateX(0px) scale(2.4, 1);
	opacity: 1;
}

.sub-menu-dash-line line {
	stroke: white;
}

.sub-menu-item {
	@include Forza(400);

	cursor: pointer;
	padding-top: 9px;
	padding-bottom: 9px;

	position: relative;
	display: flex;
	align-items: center;

	font-size: 20px;
	text-decoration: none;
}

.sub-menu-item--disabled {
	pointer-events: none;
}

.sub-menu-item-label {
	color: rgb(255, 255, 255);
	opacity: 0.65;
	@include defaultEase();
	position: relative;
	display: inline-block;
}

a.sub-menu-item-label {
	color: rgb(255, 255, 255);
	@include defaultEase();
}

.sub-menu-label-coming-soon {
	font-size: 17px;
}

.sub-menu-item--disabled .sub-menu-item-label {
	opacity: 0.3;
}

.sub-menu-item--hovered .sub-menu-item-label {
	opacity: 1;
}

.sub-menu--active .sub-menu-item {
	pointer-events: auto;
}

.sub-menu-item--hovered .sub-menu-item-label {
	transform: translateX(5px);
}
