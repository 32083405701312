@use '../../common/sass/base.module.scss' as *;

.BlogPostContent {
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	touch-action: pan-y;
}

.header {
	height: 100%;
	width: 100%;
	// display: flex;
	align-items: center;
	position: relative;
	max-height: 880px;
}

.back {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: white;
	position: absolute;
	right: 40px;
	top: 24px;
	z-index: 2;
	box-shadow: 0px 0px 7px rgb(0 0 0 / 43%);

	@media #{$query-tablet-large} {
		top: 20px;
		right: 20px;
	}
	@media #{$query-mobile} {
		top: 14px;
		right: 20px;
	}
}

.logo {
	width: 200px;
	position: absolute;
	left: 310px;
}

.header-image {
	clip-path: polygon(54px 0%, 100% 0%, calc(100% - 54px) 100%, 0% 100%);
	height: 450px;
	overflow: hidden;
	margin: 40px 0;
	width: calc(100% + 60px);
	background-size: cover;
	background-position: center;
	position: relative;
	left: 50px;
	aspect-ratio: 2/1;

	&::before {
		float: left;
		padding-top: 50%;
		content: '';
	}
	&::after {
		display: block;
		content: '';
		clear: both;
	}

	@media #{$query-tablet-large} {
		left: 0;
		clip-path: none;
		max-width: 100%;
		width: 100%;
		height: auto;
	}
}

.title-inner-wrap {
	max-width: 900px;
	position: relative;
	left: 80px;
	margin: 30px auto;
	padding-left: 50px;
	padding-right: 50px;
	margin-top: 18px;

	@media #{$query-tablet-large} {
		left: 0;
		padding-left: 66px;
		padding-right: 66px;
	}
	@media #{$query-mobile} {
		left: 0;
		padding-left: 30px;
		padding-right: 30px;
	}
}

.body-title {
	@include GothamNarrow(700);
	font-size: 50px;
	line-height: 1.2;
	margin-bottom: 10px;
	margin-top: 0;
	color: white;

	@media #{$query-tablet-large} {
		font-size: 30px;
		margin-top: 70px;
	}

	@media #{$query-mobile} {
		font-size: 20px;
		margin-top: 96px;
	}
}

.info {
	display: flex;
	align-items: center;
	color: white;
}

.info-item {
	@include GothamNarrow(300);
	font-size: 18px;
	display: flex;
	align-items: center;
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}

	svg {
		margin-right: 10px;
	}

	@media #{$query-mobile} {
		font-size: 12px;
	}
}

.detail-body {
	min-height: 450px;
	color: white;
	position: relative;
}

.content {
	min-height: 100%;
	color: white;
	max-width: 900px;
	margin: auto;

	@media #{$query-tablet} {
		padding: 0;
	}
}

.detail-body-wrap {
	width: 100%;
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: 2;
}

.inner-wrap {
	position: relative;
	left: 80px;
	margin: 0 auto;
	padding-bottom: 100px;
	padding-left: 40px;
	padding-right: 40px;

	@media #{$query-tablet-large} {
		left: auto;
		padding-left: 66px;
		padding-right: 66px;
		padding-bottom: 100px;
	}
	@media #{$query-mobile} {
		left: auto;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 30px;
	}
}
