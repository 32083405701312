@mixin css-normalize {
	* {
		box-sizing: border-box;
	}

	img {
		border: 0;
		max-width: 100%;
		height: auto;
	}

	html {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		touch-action: none;
	}

	body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		touch-action: none;
	}

	a {
		text-decoration: none;
	}

	button {
		margin: 0;
		padding: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}

	input,
	select,
	textarea {
		border: none;
		-webkit-appearance: none;
	}

	iframe {
		border: none;
	}
}
