@use '../../common/sass/base.module.scss' as *;

.RegisterButton {
	height: 56px;
	width: 100%;
	background-color: #54d185;
	border: 6px solid #10d9cf;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	transform: skewX(-10deg);
}

.text {
	@include Forza(500);
	text-transform: uppercase;
	font-size: 22px;
	transform: skewX(10deg);
}
