//EXAMPLE
//
// @mixin Gotham($weight:400, $style:normal) {
// 	font-family: 'Gotham SSm A', 'Gotham SSm B', Arial, sans-serif;
// 	font-weight: $weight;
// 	font-style: $style;
// }

@mixin Gotham($weight: 400, $style: normal) {
	font-family: 'Gotham SSm A', 'Gotham SSm B', Arial, sans-serif;
	font-weight: $weight;
	font-style: $style;
}

@mixin GothamNarrow($weight: 400, $style: normal) {
	font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', Arial, sans-serif;
	font-weight: $weight;
	font-style: $style;
}

@mixin Forza($weight: 400, $style: normal) {
	font-family: 'Forza SSm A', 'Forza SSm B', Arial, sans-serif;
	font-weight: $weight;
	font-style: $style;
}
