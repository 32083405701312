@use '../../common/sass/base.module.scss' as *;

.Foreground {
	opacity: 1;
	transition: 0.4s opacity;
	z-index: 6;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.water {
	position: absolute;
	left: 0;
	top: 0;
	transform: translateY(-100%);
	z-index: 2;
}

.runners {
	position: absolute;
	top: 0;
	left: 0;
	width: 346px;
	z-index: 3;
}

.cyclists {
	position: absolute;
	top: 0;
	left: 0;
	width: 346px;
	z-index: 3;
}

.carousel {
	position: absolute;
	top: 0;
	left: 0;
	width: 276px;
	height: 276px;
	z-index: 1;
}

.trees {
	position: absolute;
	top: 0;
	left: 0;
	width: 276px;
	z-index: 1;
}
