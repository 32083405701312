@use '../../../common/sass/base.module.scss' as *;

.icon {
	margin-right: 7px;
	position: relative;
	top: 2px;
}

.main-menu-dash-line {
	position: absolute;
	left: -78px;
	width: 100px;
	@include defaultEase();
	opacity: 0.3;
	margin-top: 1px;
}

.main-menu-item--hovered .main-menu-dash-line {
	transform: translateX(23px);
	opacity: 1;
}

.main-menu-dash-line line {
	stroke: white;
	transition: stroke 0.15s ease;
}

.is-dark .main-menu-dash-line line,
.blog-sigle .main-menu-dash-line line {
	stroke: black;
}

.main-menu-item {
	@include Forza(400);

	cursor: default;
	padding-top: 10px;
	padding-bottom: 10px;

	position: relative;
	display: flex;
	align-items: center;

	font-size: 18px;
	text-decoration: none;
	@include defaultEase();
}

.main-menu-item--hovered.main-menu-item--sub-menu {
	animation: menuItemSubMenu 0.575s ease both;
}

.main-menu-item-label {
	color: rgb(255, 255, 255);
	opacity: 0.65;
	position: relative;
	left: 32px;
	@include defaultEase();
	transition: 0.275s opacity, 0.275s transform, 0.15s color;
	display: flex;
	align-items: center;
}

a.main-menu-item-label {
	color: rgb(255, 255, 255);
	transition: 0.275s opacity, 0.275s transform, 0.15s color;
}

.main-menu--active .is-dark .main-menu-item-label,
.main-menu--active.blog .main-menu-item-label {
	color: rgb(255, 255, 255);
}

.main-menu-item--hovered .main-menu-item-label {
	opacity: 1;
}

.is-disabled {
	pointer-events: none;
}

.main-menu-item--inactive {
	opacity: 0.35;
	pointer-events: none;
}

.is-active.is-disabled {
	opacity: 0.35;
}

.main-menu-item--hovered .main-menu-item-label {
	transform: translateX(12px);
}
