@use '../../common/sass/base.module.scss' as *;

.Landscape {
	opacity: 1;
	z-index: 4;
	position: absolute;
	left: 0;
	pointer-events: none;
	overflow: hidden;
}

.halftone {
	transition: opacity 0.4s ease;
}

.City {
	z-index: 2;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;

	path {
		transition: fill 0.3s ease;
	}
}

.CityLights {
	z-index: 3;
	bottom: 4px;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;

	path {
		fill: #1d92cc;
		transition: opacity 1s ease;
	}
}

.MountainsFront {
	z-index: 1;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	transition: 0.3s opacity ease, 0.4s transform ease;
	will-change: transform, opacity;

	path {
		transition: fill 0.3s ease;
	}
}

.MountainsBack {
	z-index: 1;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	transition: 0.3s opacity ease, 0.4s transform ease;
	will-change: transform, opacity;

	path {
		transition: fill 0.3s ease;
	}
}

//

.landscape-element {
	position: absolute;
	transition: 0.3s opacity ease, 0.4s transform ease;
	will-change: transform, opacity;

	path {
		transition: fill 0.3s ease;
	}
}

.Mountains {
	z-index: 1;
	bottom: -4vh;
	transform: translateX(190px);
	width: 130vh;
}

.Mountains2 {
	z-index: 0;
	bottom: 7vh;
	transform: translateX(100px);
	width: 48vh;
}

.Mountains3 {
	z-index: 0;
	transform: translateX(150px);
	bottom: 5vh;
	width: 66vh;
}

.Logo {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateX(60px);
	z-index: 3;
	width: 38vh;
}

/* INITIAL States */
.Landscape.hidden {
	opacity: 0;

	.Mountains {
		transform: translateX(145px);
	}

	.Mountains2 {
		transform: translateX(70px);
	}

	.Mountains3 {
		transform: translateX(150px);
	}

	.City {
		transform: translateX(-50%) translateX(-32vh) translateX(60px);
	}
}

.Landscape.mobile {
	.Mountains {
		// transform: translateX(-50%) translateX(150px) Like in the XD, but cut in left visible when wider viewport
		transform: translateX(-50%);
		left: 50%;
		bottom: 100px;
	}

	.Mountains2 {
		transform: none;
		left: -100px;
		bottom: 200px;
	}

	.Mountains3 {
		display: none;
	}

	.City {
		transform: translateX(-50%) translateX(-275px);
		bottom: -20px;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -200px;
		width: 100%;
		height: 200px;
		background-color: #112932;
		z-index: 2;
	}
}
