@use './common/sass/base.module.scss' as *;

@include css-normalize;

html {
	background-color: #242424;
}

.App {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	color: #fff;
	font-family: sans-serif;
	@include GothamNarrow(300);
}

a {
	text-decoration: none;
	color: #38dfcb;
	transition: 0.3s color ease;
	cursor: pointer;
}

a:hover {
	color: #1abde2;
}

:global(strong), 
:global(b) {
  font-weight: 700;
}