@use '../../common/sass/base.module.scss' as *;

.Menu {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;

	// transform: skewX(-14deg);
	transform-origin: top left;
	z-index: 1;

	@media #{$query-tablet-large} {
		display: flex;
	}
}

.mobileOverlay {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}