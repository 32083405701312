@use '../../common/sass/base.module.scss' as *;

.Footer {
	position: relative;
	z-index: 2;
}

.inner {
	// background-color: rgb(31, 171, 163);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 120px;
	padding-left: 95px;
	padding-right: 95px;

	@media #{$query-mobile} {
		padding-left: 20px;
		padding-right: 20px;
		height: 200px;
	}
}

.inner:after {
	content: '';
	background-color: rgb(31, 171, 163);
	width: 620px;
	position: absolute;
	height: 70px;
	z-index: -1;
	border-radius: 6px;
	margin-bottom: 120px;

	@media #{$query-mobile} {
		margin-bottom: 0;
		width: 100%;
		border-radius: 0;
		height: 90%;
		bottom: 0;
	}
}

.copy {
	text-align: center;
	position: relative;
	z-index: 1;
	margin-bottom: 120px;

	@media #{$query-mobile} {
		margin-bottom: 0;
		top: -20px;
	}
}

.tree {
	position: absolute;
	left: calc(50vw - 320px);
	bottom: 0;
	line-height: 0;
	z-index: 0;

	@media #{$query-mobile} {
		left: calc(50vw - 200px);
	}
}

.inner a {
	color: white;
	margin-left: 5px;
	transition: opacity 0.25s ease;

	&:hover {
		text-decoration: underline;
	}
}
