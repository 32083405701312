.infobox {
	background-color: #353535;
	border-radius: 3px;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 1.5;
}

.infobox + p {
	margin-top: 7px;
}