@use '../../common/sass/base.module.scss' as *;

@keyframes arrowMove {
	0% {
		left: 0;
	}
	50% {
		left: -2px;
	}
	100% {
		left: 0;
	}
}

.HomeInfobox {
	@include Forza();
	margin: 0 auto;
	max-width: 520px;
	width: 100%;
	line-height: 1.333;
	text-align: center;
	margin-top: 35px;

	@media #{$query-mobile} {
		padding: 0 20px;
	}
}

.inner {
	background-color: rgba(6, 49, 77, 0.9);
	border-radius: 4px;
	width: 100%;
	padding: 10px;
}

.home-bullet {
	color: #ffffff;
	margin: auto;
	margin: 15px 20px;
	font-size: 15px;

	@media #{$query-mobile} {
		font-size: 13px;
	}
}

.learn-more {
	color: #37dbd3;
	display: inline-block;
	transition: color 0.3s;
	margin-bottom: 15px;

	svg {
		margin-left: 3px;
		position: relative;
	}

	&:hover,
	&:active {
		svg {
			animation: arrowMove 0.7s infinite;
		}
	}
}
