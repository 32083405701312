@use '../../common/sass/base.module.scss' as *;

.Overlay {
	position: absolute;
	background: rgba(111, 44, 178, 0.96);
	background: linear-gradient(to top, rgba(27, 107, 139, 0.98) 0%, rgba(69, 65, 103, 0.94) 80%);
	opacity: 0;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 3;
	pointer-events: none;
}

.overlay--active {
	pointer-events: auto;
}
