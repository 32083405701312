@use '../../common//sass/base.module.scss' as *;

.Modal {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 8;
	height: 100%;
	width: 100%;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 7;
}

.close {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 8;
}

.content {
	// @include SourceSans();
	// color: $gray-800;
	width: 100%;
	max-width: 600px;
	padding: 40px 20px;
	font-size: 22px;
	margin-top: 20px;
	text-align: center;
	background-color: #464646;
	z-index: 8;
	border-radius: 6px;
}
