/*
* === Color ===
*/

// Palette is Adobe's Darkest Theme: https://spectrum.adobe.com/page/color/
// The values here represent the relative contrast ratio with gray-100.
// Different palettes would keep the same contrast ratio.

//Temporary
$gray-65: rgb(13, 13, 13);
$gray-150: rgb(37, 37, 37);
$gray-550: rgb(112, 112, 112);
$gray-650: rgb(133, 133, 133);
$gray-450: rgb(90, 90, 90);
$gray-850: rgb(230, 230, 230);

//New
$gray-50: rgb(8, 8, 8); //#080808
$gray-75: rgb(26, 26, 26); //#1A1A1A
$gray-100: rgb(30, 30, 30); //#1E1E1E
$gray-200: rgb(44, 44, 44); //#2C2C2C
$gray-300: rgb(57, 57, 57); //#393939
$gray-400: rgb(73, 73, 73); //#494949
$gray-500: rgb(92, 92, 92); //#5C5C5C
$gray-600: rgb(124, 124, 124); //#7C7C7C
$gray-700: rgb(162, 162, 162); //#A2A2A2
$gray-800: rgb(200, 200, 200); //#C8C8C8
$gray-900: rgb(239, 239, 239); //#EFEFEF

$cyan-300: rgb(11, 107, 104); //#0B6B68
$cyan-400: rgb(6, 148, 143); //#06948F
$cyan-500: rgb(0, 175, 169); //#00AFA9
$cyan-600: rgb(14, 191, 185); //#0EBFB9
$cyan-700: rgb(34, 209, 203); //#22D1CB

$red-300: rgb(161, 32, 39); //#A12027
$red-400: rgb(215, 55, 63); //#D7373F
$red-500: rgb(227, 72, 80); //#E34850
$red-600: rgb(236, 91, 98); //#EC5B62
$red-700: rgb(247, 109, 116); //#F76D74

$celadon-blue: #174c60;
$blue-400: rgb(20, 115, 230); //#1473E6
$blue-500: rgb(38, 128, 235); //#2680EB
$blue-600: rgb(55, 142, 240); //#378EF0
$blue-700: rgb(75, 156, 245); //#4B9CF5

$orange-400: rgb(218, 123, 17); //#DA7B11
$orange-500: rgb(230, 134, 25); //#E68619
$orange-600: rgb(242, 148, 35); //#F29423
$orange-700: rgb(249, 164, 63); //#F9A43F

$mint-green: #3aba74;
$green-400: rgb(38, 142, 108); //#268E6C
$green-500: rgb(45, 157, 120); //#2D9D78
$green-600: rgb(51, 171, 132); //#33AB84
$green-700: rgb(57, 185, 144); //#39B990

$white: rgb(255, 255, 255); //---Should this be replaced with gray-900?
$faded-white: rgba(255, 255, 255, 0.75);
$faded-white-1: rgba(255, 255, 255, 0.75);
$black: rgb(0, 0, 0); //---Should this be replaced with gray-900?
$violet-400: rgb(84, 64, 148); //--- not sure yet
$violet-500: rgb(122, 90, 227);
$cyan-800: rgb(0, 175, 169);
$cool-gray: rgb(65, 66, 76); //--- in the middle of 300 and 400

$icon-color-default: #6dda92;
$icon-color-active: white;

:export {
	gray50: $gray-300;
	gray75: $gray-300;
	gray100: $gray-100;
	gray200: $gray-200;
	gray300: $gray-300;
	gray400: $gray-400;
	gray500: $gray-500;
	gray600: $gray-600;
	gray700: $gray-700;
	gray800: $gray-800;
	gray900: $gray-900;

	cyan300: $cyan-300;
	cyan400: $cyan-400;
	cyan500: $cyan-500;
	cyan600: $cyan-600;
	cyan700: $cyan-700;

	red300: $red-300;
	red400: $red-400;
	red500: $red-500;
	red600: $red-600;
	red700: $red-700;

	blue400: $blue-400;
	blue500: $blue-500;
	blue600: $blue-600;
	blue700: $blue-700;

	orange400: $orange-400;
	orange500: $orange-500;
	orange600: $orange-600;
	orange700: $orange-700;

	green400: $green-400;
	green500: $green-500;
	green600: $green-600;
	green700: $green-700;

	//Temporary
	gray150: $gray-150;
	gray65: $gray-65;
	gray450: $gray-450;
	gray550: $gray-550;
	gray650: $gray-650;
	gray850: $gray-850;
	white: $white;
	black: $black;
	violet400: $violet-400;
	violet500: $violet-500;
	coolGray: $cool-gray;

	iconColorDefault: $icon-color-default;
	iconColorActive: $icon-color-active;
}
