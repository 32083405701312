@use '../../../common/sass/base.module.scss' as *;

.SubMenu {
	position: absolute;
	top: 0;
	left: 260px;
	z-index: 5;
	height: 100%;
	min-width: 405px;

	@media #{$query-mobile} {
		left: 0;
	}
}

.SubMenuBG {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	height: 100%;
	width: 100%;

	backdrop-filter: blur(30px);
	background-color: rgba(6, 107, 148, 0.5);
	box-shadow: 0 0 45px rgba(0, 0, 0, 0.8);

	will-change: transform, opacity;

	@supports (-moz-appearance: none) {
		background-color: rgba(6, 107, 148, 1);
	}
}
