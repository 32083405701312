@use '../../common/sass/base.module.scss' as *;

.BlogList {
	margin-top: -100px;
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media #{$query-mobile} {
		justify-content: center;
	}
}
