@use '../../common/sass/base.module.scss' as *;

.MapSideSheet {
	min-height: 450px;
	width: 400px;
	background-color: #256e99;
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 1;
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	@media #{$query-tablet-large} {
		width: 320px;
		min-height: 320px;
		top: 20px;
		right: 20px;
	}
}

.header {
	aspect-ratio: 16/9;
	width: 100%;
	position: relative;

	&::before {
		float: left;
		padding-top: 56.25%;
		content: '';
	}
	&::after {
		display: block;
		content: '';
		clear: both;
	}
}

.gradient {
	content: '';
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.img {
	position: absolute;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.title {
	@include Forza(500);
	color: white;
	font-size: 30px;
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 20px;
	z-index: 2;
}

.copy {
	@include GothamNarrow();
	font-size: 18px;
	padding: 30px 40px;
	line-height: 1.33;

	@media #{$query-tablet-large} {
		font-size: 15px;
		padding: 20px;
	}
}

.back {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: white;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 2;
	cursor: pointer;

	@media #{$query-tablet-large} {
		top: 15px;
		right: 15px;
	}
}
