@use '../../common/sass/base.module.scss' as *;

.Snackbar {
	position: fixed;
	z-index: 7;
	width: 310px;
	background-color: rgba(255, 255, 255, 0.98);
	border: 3px solid #29c1b0;
	right: 20px;
	bottom: 20px;
	border-radius: 5px;
	overflow: hidden;
	padding: 20px;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);

	@media #{$query-mobile} {
		width: calc(100% - 20px);
		max-width: 400px;
		right: 10px;
		bottom: 80px;
	}

	@media #{$query-tablet-large} {
		right: 10px;
		bottom: 80px;
	}
}

.snackbar-content {
	margin: 15px 0;
}

.snackbar-title {
	color: #101010;
	@include GothamNarrow(500);
	font-size: 15px;
	margin-bottom: 5px;
}

.snackbar-copy {
	color: #101010;
	@include GothamNarrow();
	font-size: 12px;
}

.snackbar-dismiss-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.snackbar-dismiss {
	appearance: none;
	border: none;
	background: transparent;
	margin: 0;
	padding: 0;
	font-size: 13px;
	color: #21aa9b;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	@include Gotham(700);
	position: relative;
}

.snackbar-dismiss:before {
	content: '';
	position: absolute;
	top: -10px;
	left: -10px;
	width: calc(100% + 20px);
	height: calc(100% + 20px);
}

.countdown {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.countdown-column {
	display: flex;
	flex-direction: column;
	width: 22%;
	justify-content: center;
	align-items: center;
}

.countdown-value {
	color: white;
	background-color: #21aa9b;
	padding: 5px;
	border-radius: 4px;
	overflow: hidden;
	font-size: 24px;
	@include GothamNarrow(800);
	display: flex;
	justify-content: center;
	width: 100%;
}

.countdown-label {
	color: #21aa9b;
	@include GothamNarrow(500);
	font-size: 11px;
	margin-top: 5px;
}

.big {
	&.Snackbar {
		width: 500px;
		border: 5px solid rgb(231, 30, 30);

		@media #{$query-mobile} {
			width: calc(100% - 20px);
		}
	}

	.snackbar-title {
		font-size: 32px;
		margin-bottom: 12px;

		@media #{$query-mobile} {
			font-size: 24px;
		}
	}

	.snackbar-copy {
		font-size: 18px;

		@media #{$query-mobile} {
			font-size: 16px;
		}
	}
}

.normal {
	&.Snackbar {
		width: 510px;

		@media #{$query-mobile} {
			width: calc(100% - 20px);
		}
	}

	.snackbar-title {
		font-size: 28px;
		margin-bottom: 12px;

		@media #{$query-mobile} {
			font-size: 20px;
		}
	}

	.snackbar-copy {
		font-size: 16px;

		@media #{$query-mobile} {
			font-size: 14px;
		}
	}
}
