@use '../../common/sass/base.module.scss' as *;

.SponsorTicker {
	position: fixed;
	right: 0;
	top: 0;
	height: 100vh;
	width: 160px;
}

.header {
	@include Gotham(700);
	background-color: #1f5675;
	width: 160px;
	height: 40px;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
    line-height: 40px;
    font-size: 14px;
    color: #5faac8;
    letter-spacing: 2px;
}

.background {
	background-color: rgba(0,0,0);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	opacity: .35;
}

.sponsors {
	position: relative;
}

.sponsor {
	text-align: center;
	width: 100%;
	position: absolute; 
	height: 100%;
}

.img {
	height: 160px;
	width: auto;
	padding: 20px;
	margin: auto;
	display: block;
}