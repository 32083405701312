@use '../../common/sass/base.module.scss' as *;

.Home {
	position: absolute;
	top: 0;
	left: 20px;
	width: 100%;
	height: 100%;
	z-index: 5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@media #{$query-tablet-large} {
		left: 0;
	}
}

.content {
	position: relative;
	top: -60px;
}

.logo-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.backdrop {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 68vh;
	width: 68vh;
	margin-left: -34vh;
	margin-top: -34vh;
	background: radial-gradient(circle at center, rgba(16, 111, 156, 1) 20%, rgba(21, 88, 120, 0) 50%);
	z-index: -1;
	opacity: 0.5;
	transition: opacity 0.1s ease;
}

.logo {
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	left: 0;
}

.logo img {
	filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.3));
	width: 480px;

	@media #{$query-tablet-large} {
		width: 380px;
	}
	@media #{$query-mobile} {
		width: 80vw;
		max-width: 400px;
	}
}

.sub {
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 380px;
	margin: 20px auto 20px;

	@media #{$query-tablet-large} {
		width: 360px;
		margin: 18px auto 24px;
	}
	@media #{$query-mobile} {
		width: 70vw;
		max-width: 300px;
		margin: 0 auto;
	}
}

.date {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.5));
	margin-top: 5px;
}

.date-inner {
	width: 220px;

	@media #{$query-tablet-large} {
		width: 180px;
	}
	@media #{$query-mobile} {
		width: 50vw;
		max-width: 250px;
	}
}

.buttons {
	display: flex;
	align-items: center;
	justify-content: center;

	@media #{$query-mobile} {
		flex-direction: column;
		position: static;
		display: inline-flex;
		transform: none;
		margin-top: 14px;
	}
}

.button {
	margin-right: 60px;
	transform: scale(1.2);

	@media #{$query-mobile} {
		margin-right: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}
