@use '../../../common/sass/base.module.scss' as *;

.Policy {
	position: relative;
}

a.label {
	@include Forza(500);
	opacity: 0.5;
	transition: 0.15s opacity ease;

	color: #fff;
}

a.label:hover {
	opacity: 1;
}
