@use '../../common/sass/base.module.scss' as *;

.jdrf-intro-logo {
    width: 50%;
    height: 50%;
    position: relative;
}

.video {
    max-width: 675px;
    width: 100%;
    aspect-ratio: 16/9;
}

.video-vimeo {
    // width: 640px;
    max-width: 675px;
    width: 100%;
    // height: 360px;
    height: 379px;
}
