@use '../../common/sass/base.module.scss' as *;

.LogoBox {
	width: 100%;
	aspect-ratio: 6;
	border-radius: 6px;
	overflow: hidden;
	position: relative;
	height: auto;
	will-change: transform;

	&::before {
		float: left;
		padding-top: 17%;
		content: '';
	}
	&::after {
		display: block;
		content: '';
		clear: both;
	}
}

.blade1 {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% + 110px);
	height: 100%;
	background-color: #91c950;
	transform: skewX(-55deg);
}

.blade2 {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% + 110px);
	height: 100%;
	background-color: #75b943;
	transform: skewX(-55deg);
}

.blade3 {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% + 110px);
	height: 100%;
	background-color: #6ca542;
	transform: skewX(-55deg);
}

.name {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 265px;
	z-index: 2;
	transform: translate(-50%, -50%) translateY(3px);
}
